.dataTableContainer {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 97%;
  }
  
  .dataTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dataTable th, .dataTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .dataTable th {
    background-color: #4CAF50;
    color: white;
  }
  
  .dataTable td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
  
  .dataTable td:hover {
    overflow: visible;
    white-space: normal;
    position: absolute;
    background-color: #f5f5f5;
    z-index: 1;
  }
  