html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

.recharts-legend-item-text{
  font-size: x-small;
}

.tagName {
  font-size: x-small;
  padding-left: 15px;
  position: absolute;
  left: -5px;
  top: 5px;
}

#start-date {
  height: 0.4375em;
}

#end-date {
  height: 0.4375em;
}
