.logged-in .App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.logged-in .Navbar {
  flex-shrink: 0;
}

.logged-in .DrawerComponent {
  flex-shrink: 0;
}
 
.logged-in main {
  height: 100%;
}

/* drawer */
.logged-in #root > div > div > div {
  background-color: #415057;
  color: white;
}

/* mobile view drawer */
.logged-in body > div.MuiDrawer-modal.drawer > div.MuiPaper-root.MuiPaper-elevation {
  background-color: #415057;
  color: white;
}

.logged-in body {
  background-color: #f0f0f0 !important;
}

.logged-in .MuiSvgIcon-root {
  color: white;
}

.logged-in .react-grid-item {
  background-color: white;
  margin: 20px;
  padding: 20px;
  border-radius: 5px;
}

.logged-in .remove {
  color: rgb(0, 0, 0);
  border-radius: 5px;
  background-image: none !important;
  padding: 0 !important;
  
}
.logged-in .remove::after {
  width: 0 !important;;
  height: 0 !important;;
  background-image: none !important;
  padding: 0 !important;;
  border-right: 0 !important;;
  border-bottom: 0 !important;;
}

/* inner svg */
.logged-in .MuiBox-root .MuiSvgIcon-root {
  color: black;
}

.logged-in .no-widgets-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;  /* Adjust based on your requirement */
  margin: 20px;
  background-color: rgb(218, 218, 218);
  color: hsl(330, 1%, 41%);
  border: 1px solid #979797;
  border-radius: 10px;
  text-align: center;
}

.logged-in .no-widgets-message p {
  font-size: 18px;
  font-weight: 500;
}

.logged-in .MuiDrawer-paperAnchorLeft {
  background-color: #415057 !important;
  color: white;
}
